.Actions {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  padding-top: 20px;
  padding-left: 29px;
  padding-right: 29px;
  background-color: var(--white-color);
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 22px;
    h2 {
      width: 71px;
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      color: var(--black-color);
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 30px;
      font-weight: 500;
      font-size: 14px;
      color: var(--white-color);
      background-color: var(--brand-color);
      border-radius: 20px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 44px;
    width: 100%;
  }

  .noActions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 153px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-size: 20px;
    text-align: center;
    color: var(--gray-color);
  }
}

@media (max-width: 1024px) {
  .Actions {
    min-width: 337px;
  }
}

.Action {
  .title {
    margin-bottom: 4px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: var(--black-color);
  }

  .date,
  .text {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: var(--black-color);
    white-space: nowrap;
  }

  .date {
    color: var(--gray-color);
  }
}

.LandingPage {
  padding-bottom: 100px;
  background-color: var(--secondary-color);

  &.preview {
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 222;
      width: 100%;
      height: 100%;
    }

    .controlHeight {
      height: 838px;
    }

    .fullScreen {
      height: 926px;
    }
  }

  .controlHeight {
    height: 100vh;
    width: 0;
    position: absolute;
  }

  .whiteButtonContainer {
    width: 444px;
  }

  .fullScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 88px);
    padding-top: 76px;
    background-image: url(../../assets/images/landing-image-1.png),
      url(../../assets/images/landing-image-2.png);
    background-repeat: no-repeat;
    background-position: left 70px bottom, right 70px bottom;

    h1 {
      margin: 0;
      margin-bottom: 60px;
      font-family: 'Sentinel';
      font-style: normal;
      font-weight: 400;
      font-size: 90px;
      text-align: center;
      color: var(--black-color);

      .kfc {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 120px;
        line-height: 120px;
        text-align: center;
        text-decoration-line: underline;
        color: var(--brand-color);
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 68px;

      p {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: var(--black-color);

        .positionCount {
          color: var(--brand-color);
        }
      }
    }
  }

  .contentBox {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    padding-left: 70px;
    padding-right: 70px;

    .textBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .workButton {
        width: 200px;
        height: 64px;
        margin-bottom: 50px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: var(--brand-color);
        background-color: var(--white-color);
        border-radius: 16px;
        border: none;
        cursor: auto;
      }

      h2 {
        margin: 0;
        margin-bottom: 50px;
        font-family: 'Sentinel';
        font-style: normal;
        font-weight: 400;
        font-size: 90px;
        line-height: 86px;
        color: var(--black-color);
      }

      p {
        margin: 0;
        margin-bottom: 50px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: var(--black-color);
      }
    }
  }
}

@media (max-width: 1557px) {
  .LandingPage {
    .fullScreen {
      background-size: 16%, 23%;
    }
  }
}

@media (max-width: 1024px) {
  .LandingPage {
    &.preview {
      .controlHeight {
        height: 926px;
      }

      .fullScreen {
        height: 883px;
      }
    }

    .fullScreen {
      height: calc(100svh - 43px);
      padding-top: 45px;
      background-image: url(../../assets/images/landing-image-mobile.png);
      background-repeat: no-repeat;
      background-size: unset;
      background-position: bottom 56px center;

      h1 {
        margin-bottom: 20px;
        font-family: 'Sentinel';
        font-style: normal;
        font-weight: 400;
        font-size: 54px;
        text-align: center;
        color: var(--black-color);
        .kfc {
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 80px;
          line-height: 91px;
        }
      }

      .whiteButtonContainer {
        width: 100%;
        margin-top: auto;
        margin-bottom: 2px;
        padding-left: 16px;
        padding-right: 16px;
      }

      .info {
        p {
          width: 100%;
          font-size: 28px;
          line-height: 20px;
        }
      }
    }

    .contentBox {
      flex-direction: column;
      justify-content: unset;
      padding-top: 40px;
      padding-left: 16px;
      padding-right: 16px;
      .textBlock {
        align-items: center;
        padding-top: 20px;
        .workButton {
          margin-bottom: 20px;
        }

        h2 {
          width: 100%;
          margin-bottom: 20px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 46px;
          line-height: 80px;
          color: var(--black-color);
        }

        p {
          margin-bottom: 20px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          color: var(--black-color);
        }
      }
    }
  }
}

@media (max-height: 571px) {
  .LandingPage {
    .fullScreen {
      background-size: 200px;
    }
  }
}

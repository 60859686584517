.JobPage {
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 119px;
  background-color: var(--secondary-color);
  font-family: 'National 2 Condensed';

  &.fetching {
    button {
      visibility: hidden;
    }
  }

  &.noMedia {
    .container {
      gap: unset;
    }
  }

  &.iframe {
    // height: calc(100vh - 88px);
    padding-left: 39px;
    padding-right: 39px;
    padding-bottom: 39px;

    .jobPostCoverImageContainer {
      width: 343px;

      img {
        width: 343px;
        min-width: 343px;
        height: 608px;
      }
    }

    .applyButtonContainer {
      display: none;
    }

    .header {
      height: 36px;
      * {
        display: none;
      }
    }
  }

  .jobPostCoverImage {
    min-width: 414px;
    max-width: 414px;
    height: 736px;
    box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    object-fit: cover;
  }

  .header {
    position: relative;
    height: 70px;
  }
  .container {
    display: flex;
    gap: 50px;
  }
  &__content {
    width: 100%;
  }
  &__title-container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  &__title {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.73;
    word-break: break-word;
    color: var(--black-color);
  }

  .applyButtonContainer {
    flex-shrink: 0;
    margin-top: 16px;
  }

  &__bookmarkBtn {
    background-color: transparent;
    border: none;
    margin-right: 25px;
  }
  &__badges {
    display: flex;
    gap: 10px;
    margin-bottom: 26px;

    .badge {
      display: flex;
      align-items: center;
      height: 36px;
      padding-left: 18px;
      padding-right: 18px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: var(--white-color);
      background-color: black;
      border-radius: 8.5px;
      user-select: none;
    }
  }
  &__conditions {
    display: flex;
    gap: 70px;
    margin-bottom: 27px;
  }
  &__jobtype {
    display: flex;
    align-items: center;
  }
  &__property-title {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: var(--black-color);
  }

  .jobConditions {
    display: flex;
    flex-wrap: wrap;
    gap: 82px;
    margin-bottom: 26px;

    .col {
      display: flex;
      align-items: center;
      gap: 10px;

      .value {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 106px;
        min-height: 36px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--black-color);
        white-space: nowrap;
        background-color: var(--white-color);
        border-radius: 8.5px;

        span {
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .title {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: var(--black-color);
        white-space: nowrap;
      }
    }
  }

  .jobType {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    background: var(--white-color);
    border-radius: 8.5px;
    height: 36px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--black-color);

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__position {
    display: flex;
    align-items: center;
  }
  &__property {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white-color);
    border-radius: 8.5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.22;
    height: 36px;
    padding: 0px 20px;
    margin: 0;
  }
  &__location-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  &__location {
    font-weight: 500;
    font-size: 22px;
    line-height: 1.36;
    color: var(--black-color);
    margin: 0;
    white-space: nowrap;
  }
  &__property-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white-color);
    border-radius: 8.5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.22;
    padding: 0px 20px;
    margin: 0;
    border: none;
  }
  &__skills-wrap {
    margin-bottom: 26px;
  }
  &__skills-list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 8.5px;
    height: 36px;
    gap: 20px;
    flex-wrap: wrap;
  }
  &__skills-item {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--black-color);
    white-space: nowrap;
  }
  .jobRequirements {
    display: flex;
    gap: 50px;
    margin-bottom: 27px;

    .experience,
    .education {
      display: flex;
      align-items: center;
      gap: 20px;

      .title {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: var(--black-color);
      }

      .value {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 106px;
        height: 36px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--black-color);
        background-color: var(--white-color);
        border-radius: 8.5px;
      }
    }
  }
  &__description-wrap {
    margin-bottom: 23px;
    width: 100%;
  }
  &__description-title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__description-add-info {
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.22;
    color: var(--brand-color);
    margin: 0;
  }
  &__description {
    font-size: 22px;
    line-height: 1.36;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--gray-color);
    width: 100%;
    word-break: break-all;
  }
  &__share-wrap {
    display: flex;
    gap: 12px;
    align-items: center;

    h2 {
      margin: 0;
    }
  }
  &__share-list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 12px;
  }
  &__share-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 50%;
  }
  &__active-wrap {
    display: flex;
    margin-left: auto;
  }
  &__share-mobile {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
  }

  .jobCreatedAt {
    margin-bottom: 0;
    margin-right: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.42;
    color: var(--gray-color);
    text-align: right;
  }

  .jobStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 36px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: var(--white-color);
    background-color: #0acf83;
    border-radius: 8.5px;

    &.onHold {
      color: var(--white-color);
      background-color: black;
    }

    &.closed {
      color: white;
      background-color: var(--gray-color);
    }
  }

  .jobApplicationStatus {
    position: absolute;
    right: 0;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0;

    .checkStatusButton {
      font-family: 'National 2 Condensed';
      background-color: transparent;
      font-weight: 500;
      border: none;
      color: var(--brand-color);
    }
  }
}

@media (max-width: 1024px) {
  .JobPage {
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 20px;
    padding-bottom: 60px;

    &.scrolled {
      .badges {
        margin-top: 0;
      }

      .jobApplicationStatus {
        margin-top: 14px;
      }
    }

    .jobPostCoverImageContainer {
      height: calc(100dvh - 45px - 40px - 15px - 15px - 90px);
      .jobPostCoverImage {
        min-width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }

    .header {
      position: relative;
      height: 70px;
      display: block;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.66;
      color: var(--black-color);
      margin: 0;
    }
    &__bookmarkBtn {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
    }
    &__title-container-mobile {
      display: flex;
      gap: 15px;
    }
    .jobApplicationStatus {
      position: static;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
    }
    &__badges {
      display: flex;
      flex-wrap: wrap;
      margin-top: 62px;
    }

    &__property-title {
      font-size: 24px;
      line-height: 1.25;
      flex-wrap: wrap;
      margin-bottom: 0 !important;
    }
    .jobConditions {
      display: flex;
      gap: 28px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 26px;

      .col {
        .value {
          min-width: unset;
          padding-left: 15px;
          padding-right: 15px;

          span {
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }
        }

        .title {
          font-size: 24px;
          line-height: 30px;
          white-space: nowrap;
        }
      }
    }

    &__location-wrap {
      display: flex;
      flex-wrap: wrap;

      gap: 20px;
    }
    &__location {
      align-items: center;

      overflow: hidden;
      text-overflow: ellipsis;

      img {
        position: relative;
        top: -3px;
      }
    }

    &__property-btn {
      border-radius: 15px;
      white-space: nowrap;
    }
    &__skills-wrap {
      margin-bottom: 28px;
    }
    &__skills-list {
      height: unset;
      flex-wrap: wrap;
      row-gap: 23px;
      column-gap: 10px;
    }
    .jobRequirements {
      flex-direction: column;
      justify-content: unset;
      gap: 28px;
      width: 100%;

      .experience,
      .education {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .title {
          font-size: 24px;
          line-height: 30px;
        }

        .value {
          padding-left: 15px;
          padding-right: 15px;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
    }
    &__description-wrap {
      margin-bottom: 28px;
      width: 100%;
    }
    &__description-title-wrap {
      display: block;
    }
    &__property-title {
      margin-bottom: 10px;
    }
    &__description-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
    &__description {
      padding: 0;
      border: 0;
      margin-bottom: 5px;
    }
    &__description-add-info {
      span {
        text-decoration: underline;
      }
    }
    &__share-wrap {
      display: block;
    }
    &__share-mobile {
      display: flex;
    }
    &__share-list {
      gap: 10px;
    }
    &__active-btn {
      font-size: 20px;
      line-height: 1.5;
      max-width: 87px;
    }
    &__share-addtext {
      font-size: 14px;
      line-height: 1.14;
    }
  }
}

@media (max-width: 408px) {
  .JobPage {
    &__share-mobile {
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

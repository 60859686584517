.Footer {
  padding-top: 41px;
  padding-bottom: 23px;
  background-color: var(--white-color);

  &.nofooter {
    display: none;
  }

  nav {
    margin-bottom: 86px;
    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      padding-left: 74px;
      padding-right: 74px;
      list-style: none;

      li {
        a {
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 30px;
          color: black;
          text-decoration: none;
        }
      }
    }
  }

  .badges {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 60px;

    .parmakImg {
      width: 444px;
      height: 97px;
      margin-bottom: 20px;
    }

    .badgesImg {
      width: 445px;
      height: 127px;
    }
  }

  .socButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 95px;

    h2 {
      margin-bottom: 9px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: var(--black-color);
      text-transform: uppercase;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 19px;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 1024px) {
  .Footer {
    padding-top: 48px;
    padding-bottom: 90px;
    nav {
      margin-bottom: 40px;
      ul {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        li {
          a {
          }
        }
      }
    }

    .badges {
      margin-bottom: 40px;
      .parmakImg {
        width: 250px;
        height: auto;
      }

      .badgesImg {
        width: 250px;
        height: auto;
      }
    }

    .socButtons {
      margin-bottom: 49px;
    }
  }
}

.ReviewVideos {
  position: relative;
  width: 321px;
  height: 723px;
  border: 3px solid var(--brand-color);
  border-radius: 20px;
  overflow: hidden;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1024px) {
  .ReviewVideos {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    border-radius: 0;
    border: none;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .buttons {
      position: absolute;
      z-index: 100;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 51px;

      .replayButton {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
        padding: 0;
        background-color: transparent;
        border: none;
      }

      .retakeButton {
        width: 87px;
        height: 40px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        background-color: transparent;
        border: 1.5px solid var(--white-color);
        border-radius: 50px;
      }

      .useButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        width: 87px;
        height: 40px;
        background-color: var(--white-color);
        border: 1.5px solid var(--white-color);
        border-radius: 50px;

        span {
          padding-top: 2px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          text-align: center;
          color: var(--brand-color);
          text-transform: uppercase;
        }
      }
    }
  }
}

.JobDate {
  position: relative;
  flex: 1;

  .calendarContainer {
    position: absolute;
    z-index: 100;
    //  bottom: 0;
    //  right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 16px;
    background-color: var(--white-color);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

    &.startDate {
      right: unset;
      left: 0;
    }
  }

  .label {
    margin: 0;
    margin-bottom: 8px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--black-color);
  }

  .date {
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 12px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--black-color);
    background-color: #f2f4f8;
    border: 1px solid #f2f4f8;
    border-radius: 10px;

    .placeholder {
      color: var(--gray-color);
    }
  }
}

@media (max-width: 1024px) {
  .JobDate {
    .calendarContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 15px;
      // transform: scale(0.9);
      background-color: rgba(0, 0, 0, 0.4);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .innerContainer {
        width: 100%;
        max-width: 436px;
        padding: 20px;
        border-radius: 16px;
        background-color: var(--white-color);
      }
    }
  }
}

.JobsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 19px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 90px;
  background-color: var(--secondary-color);

  &.preview {
    position: relative;

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 222;
      width: 100%;
      height: 100%;
    }
  }

  .header {
    position: relative;
    width: 100%;
    margin-bottom: 44px;

    .cityName {
      display: flex;
      justify-content: center;
      padding-top: 42px;
      h1 {
        margin: 0;
        padding-left: 29px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        text-align: center;
        color: var(--black-color);
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0853 22.4405C11.9137 21.5148 19.3682 17.3041 19.3682 10.1841C19.3682 4.83572 15.0325 0.5 9.68411 0.5C4.33572 0.5 0 4.83572 0 10.1841C0 17.3041 7.45448 21.5148 9.28289 22.4405C9.53703 22.5692 9.8312 22.5692 10.0853 22.4405ZM9.68411 14.3344C11.9763 14.3344 13.8344 12.4763 13.8344 10.1841C13.8344 7.89195 11.9763 6.03378 9.68411 6.03378C7.39195 6.03378 5.53378 7.89195 5.53378 10.1841C5.53378 12.4763 7.39195 14.3344 9.68411 14.3344Z' fill='%23242833'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: left 0 center;
      }
    }
  }

  .jobs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    gap: 20px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .JobsPage {
    padding-left: 14px;
    padding-right: 14px;

    .header {
      margin-bottom: 23px;
      button {
        display: none;
      }
      .cityName {
        padding-top: 17px;
        h1 {
          padding-top: 5px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          color: var(--black-color);
        }
      }
    }

    .jobs {
      position: static;
      flex-direction: column;
      gap: 26px;
      width: 100%;
    }
  }
}

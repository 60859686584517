.MobileMenu {
  .toggle {
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .closeButton {
    position: absolute;
    z-index: 1001;
    top: 32px;
    right: 27px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .searchBarContainer {
    margin-top: 20px;
    margin-bottom: -10px;
  }

  .menu {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 29px;
    padding-left: 45px;
    padding-right: 16px;
    padding-bottom: 29px;
    list-style: none;
    background-color: var(--white-color);

    button {
      padding: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      background-color: transparent;
      border: none;

      &.signIn {
        color: var(--brand-color);
      }
    }

    a {
      text-decoration: none;
      span {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: black;
      }
    }

    .languagesList {
      padding: 0;
      display: flex;
      gap: 20px;
      font-family: 'National 2 Condensed';
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      list-style: none;

      .lang {
        cursor: pointer;

        &.selectedLanguage {
          color: #d52d34;
          text-decoration-line: underline;
        }
      }
    }
  }
}

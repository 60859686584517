.Notification {
  position: relative;
  width: 100%;
  // height: 132px;
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 50px;
  padding-bottom: 22px;
  background-color: rgba(193, 197, 214, 0.1);
  border-bottom: 1.5px solid #d9d9d9;
  cursor: pointer;

  &.read {
    background-color: transparent;
    &::after {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 7.5px);
    right: 27px;
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--brand-color);
    border-radius: 50%;
  }

  .title {
    margin: 0;
    margin-bottom: 6px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: var(--black-color);
  }

  .text {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    word-wrap: break-word;
    color: var(--black-color);

    .createdAt {
      color: var(--gray-color);
    }
  }

  .buttons {
    display: flex;
    gap: 16px;

    button {
      min-width: 112px;
      height: 30px;
      padding-left: 19px;
      padding-right: 19px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: var(--secondary-color);
      border: none;
      border-radius: 10px;

      &.button {
        background-color: var(--brand-color);
      }

      &.secondaryButton {
        background-color: var(--gray-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .Notification {
    padding-left: 16px;
    padding-right: 28px;
    &::after {
      right: 16px;
      width: 10px;
      height: 10px;
    }

    .title {
      font-size: 18px;
    }

    .text {
      font-size: 16px;
    }
  }
}

.OfferForm {
  position: relative;
  width: 629px;
  min-height: 466px;
  margin-bottom: 35px;
  padding: 40px;
  background-color: #f2f4f8;
  border-radius: 20px;

  .counterOfferContainer {
    position: absolute;
    top: 140px;
    right: 40px;
    display: flex;
    align-items: center;
    gap: 9px;

    h5 {
      margin: 0;
    }

    input {
      display: flex;
      align-items: center;
      width: 180px;
      height: 48px;
      padding-right: 20px;
      padding-left: 20px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 50px;
      text-align: right;
      color: var(--black-color);
      background-color: var(--white-color);
      border: 1px solid var(--gray-color);
      border-radius: 16px;

      &:focus {
        outline: none;
      }
    }
  }

  .heading {
    margin-bottom: 5px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: var(--black-color);
  }

  .row {
    display: flex;
    justify-content: space-between;

    &.jobTitle {
      max-width: 50%;
      word-break: break-all;
    }

    .offerTitle {
      margin-bottom: 17px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 50px;
      color: var(--black-color);
    }

    .offerAmount {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      text-align: right;
      color: var(--black-color);

      &.counterOffer {
        color: var(--brand-color);
      }
    }

    .additionalInfo {
      padding-left: 22px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #1e1e1e;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2739 6.11197C16.2179 6.17797 16.2259 6.25997 16.2159 6.33597C16.0759 7.28997 15.6859 8.13197 15.0539 8.85797C14.8979 9.03797 14.7299 9.20597 14.5619 9.37397C12.6619 11.274 10.7619 13.174 8.86191 15.074C8.57391 15.362 8.2639 15.426 7.9579 15.262C7.5659 15.052 7.4619 14.544 7.7419 14.198C7.7899 14.14 7.8419 14.084 7.8959 14.032C9.8419 12.086 11.7879 10.138 13.7359 8.19197C14.2479 7.67997 14.6199 7.08797 14.7639 6.37197C15.0219 5.09197 14.6979 3.97197 13.7679 3.05597C12.9919 2.29397 12.0359 1.97797 10.9559 2.07397C10.1059 2.14997 9.3759 2.50997 8.7719 3.11397C6.7779 5.10597 4.7839 7.09797 2.7939 9.09397C2.1979 9.69197 2.0039 10.418 2.2439 11.226C2.4839 12.036 3.0439 12.534 3.8719 12.704C4.5419 12.842 5.1439 12.668 5.6679 12.23C5.7259 12.182 5.7799 12.128 5.8339 12.074C7.6379 10.27 9.4399 8.46797 11.2439 6.66397C11.5239 6.38397 11.5879 6.07197 11.4279 5.77197C11.2219 5.38597 10.7239 5.27397 10.3759 5.53997C10.3199 5.58197 10.2679 5.63197 10.2179 5.68197C9.0579 6.84797 7.8999 8.00597 6.7399 9.16597C6.4299 9.47597 6.0379 9.50997 5.7339 9.25597C5.4459 9.01597 5.3959 8.58797 5.6219 8.28797C5.6579 8.23997 5.6999 8.19597 5.7439 8.15197C6.9139 6.98197 8.0819 5.80997 9.2539 4.64197C9.9979 3.89997 11.0459 3.76597 11.9199 4.29397C13.0399 4.96997 13.2699 6.54797 12.3919 7.51797C12.3419 7.57397 12.2899 7.62997 12.2359 7.68397C10.4439 9.47597 8.6499 11.268 6.8579 13.062C6.3299 13.59 5.7119 13.95 4.9759 14.1C3.0999 14.484 1.2539 13.308 0.825904 11.424C0.533904 10.144 0.873904 9.01597 1.7979 8.08597C3.7799 6.08997 5.7739 4.10397 7.7639 2.11597C8.4879 1.39397 9.3479 0.907973 10.3559 0.733973C12.1699 0.419973 13.7259 0.931973 14.9859 2.28197C15.7079 3.05597 16.1039 3.98797 16.2379 5.03397C16.2419 5.06197 16.2279 5.09797 16.2699 5.10997C16.2739 5.44397 16.2739 5.77797 16.2739 6.11197Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .jobId {
      margin-bottom: 29px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: var(--black-color);
    }

    .workLocation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      margin-top: 5px;
      margin-bottom: 29px;
      padding-left: 20px;
      padding-right: 17px;
      background-color: var(--white-color);
      border-radius: 16px;

      .location {
        padding-top: 2px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--black-color);
      }

      button {
        width: 163px;
        height: 38px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--black-color);
        opacity: 0.9;
        background-color: #f2f4f8;
        border: 1px solid var(--white-color);
        border-radius: 15px;
      }
    }

    .message {
      max-height: 60px;
      margin-top: 5px;
      margin-bottom: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: var(--black-color);
      text-align: left;
      overflow-y: auto;
    }
  }

  .recruiterMessage {
    margin-top: 25px;
  }
}

@media (max-width: 1024px) {
  .OfferForm {
    width: 100%;
    height: unset;
    padding: 22px;
    .counterOfferContainer {
      position: static;
      margin-bottom: 28px;

      input {
        width: 144px;
      }
    }
  }
}

.CityJobsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 33px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 90px;
  background-color: var(--secondary-color);

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 17px;

    h1 {
      margin: 0;
      margin-bottom: 14px;
      width: 648px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 54px;
      line-height: 60px;
      text-align: center;
      color: var(--black-color);
    }

    .info {
      margin: 0;
      margin-bottom: 33px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: var(--black-color);

      span {
        color: var(--brand-color);
      }
    }
  }

  .cities {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(444px, 1fr));
    column-gap: 20px;
    row-gap: 24px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .CityJobsPage {
    padding-top: 27px;
    padding-left: 14px;
    padding-right: 14px;
    .header {
      padding-top: 0;
      h1 {
        width: 100%;
        margin-bottom: 20px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--black-color);
      }

      .info {
        margin-bottom: 24px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: var(--black-color);
      }

      button {
        display: none;
      }
    }

    .cities {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

.SearchBar {
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_925_24407)'%3E%3Cpath d='M11 18C15.4183 18 19 14.4183 19 10C19 5.58172 15.4183 2 11 2C6.58172 2 3 5.58172 3 10C3 14.4183 6.58172 18 11 18Z' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.9999 20.0004L16.6499 15.6504' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_925_24407'%3E%3Crect width='24' height='24' fill='var(--white-color)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-color: var(--white-color);
  background-repeat: no-repeat;
  background-position: left 16px center;
  border-radius: 15px;

  .closeSearch {
    position: absolute;
    top: calc(50% - 2px);
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }

  .searchHistory {
    position: absolute;
    bottom: -11px;
    z-index: 1;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    list-style: none;
    background-color: var(--white-color);
    border-radius: 16px;

    li {
      display: flex;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.9999 19.75C16.3846 19.75 20.7499 15.3848 20.7499 10C20.7499 4.61522 16.3846 0.25 10.9999 0.25C5.61508 0.25 1.24985 4.61522 1.24985 10C1.24985 12.3554 2.09072 14.5746 3.56678 16.3107H1.31787C0.903657 16.3107 0.567871 16.6464 0.567871 17.0607C0.567871 17.4749 0.903657 17.8107 1.31787 17.8107L5.56051 17.8107C5.97473 17.8107 6.31051 17.4749 6.31051 17.0607V12.818C6.31051 12.4038 5.97473 12.068 5.56051 12.068C5.1463 12.068 4.81051 12.4038 4.81051 12.818V15.4556C3.4989 13.9698 2.74985 12.0466 2.74985 10C2.74985 5.44365 6.4435 1.75 10.9999 1.75C15.5562 1.75 19.2499 5.44365 19.2499 10C19.2499 14.5563 15.5562 18.25 10.9999 18.25C10.5856 18.25 10.2499 18.5858 10.2499 19C10.2499 19.4142 10.5856 19.75 10.9999 19.75ZM10.9999 5.05072C11.4141 5.05072 11.7499 5.3865 11.7499 5.80072V10.6893L14.1856 13.1251C14.4785 13.418 14.4785 13.8929 14.1856 14.1858C13.8927 14.4786 13.4178 14.4786 13.1249 14.1858L10.4695 11.5303C10.3289 11.3897 10.2499 11.1989 10.2499 11V5.80072C10.2499 5.3865 10.5856 5.05072 10.9999 5.05072Z' fill='black'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: left 10px center;

      span {
        display: inline-block;
        width: 100%;
        padding-left: 45px;
        padding-right: 15px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }

  input {
    width: 100%;
    max-width: 100%;
    height: 50px;
    padding-top: 5px;
    padding-left: 43px;
    padding-right: 43px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background-color: transparent;
    border-radius: 15px;
    border: none;

    &::placeholder {
      color: var(--gray-color);
    }

    &:focus {
      outline: none;
      border: 1px solid var(--white-color);
      box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .SearchBar {
    &:not(.alwaysWhite) {
      background-color: #f2f4f8;

      input {
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

.CityCard {
  position: relative;
  // width: 444px;
  height: 269px;
  color: var(--white-color);
  mix-blend-mode: normal;
  background-size: cover;
  border-radius: 15px;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.084))
    drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.168));
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0.12deg,
      #000000 0.11%,
      rgba(0, 0, 0, 0.0001) 99.89%
    );
    opacity: 0.7;
    border-radius: 15px;
  }

  .container {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    color: unset;
    text-decoration: none;
    border-radius: 15px;

    header {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin: 0;
        margin-bottom: 7px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 40px;
        text-align: center;
      }

      .badge {
        margin: 0;
        padding-left: 28px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9926 11.1917C12.4285 11.1917 13.5926 10.0276 13.5926 8.5917C13.5926 7.15576 12.4285 5.9917 10.9926 5.9917C9.55664 5.9917 8.39258 7.15576 8.39258 8.5917C8.39258 10.0276 9.55664 11.1917 10.9926 11.1917Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M4.00894 7.07484C5.6506 -0.141827 16.3423 -0.133494 17.9756 7.08317C18.9339 11.3165 16.3006 14.8998 13.9923 17.1165C12.3173 18.7332 9.66727 18.7332 7.98394 17.1165C5.68394 14.8998 3.0506 11.3082 4.00894 7.07484Z' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: left 0 center;
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: auto;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 27px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      div {
        display: flex;
        align-items: center;
        height: 24px;
        padding-top: 4px;
        padding-left: 36px;
        background-repeat: no-repeat;
        background-position: left 0 center;
      }

      .jobCount {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.65 11C19.34 12.68 16.7 13.68 14 14.02' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.61914 11.27C4.86914 12.81 7.40914 13.74 9.99914 14.03' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      .restaurantCount {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5007 22.0003H4.0807C2.9207 22.0003 1.9707 21.0703 1.9707 19.9303V5.09035C1.9707 2.47035 3.9207 1.28035 6.3107 2.45035L10.7507 4.63035C11.7107 5.10035 12.5007 6.35035 12.5007 7.41035V22.0003Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.97 15.0599V18.8399C21.97 20.9999 20.97 21.9999 18.81 21.9999H12.5V10.4199L12.97 10.5199L17.47 11.5299L19.5 11.9799C20.82 12.2699 21.9 12.9499 21.96 14.8699C21.97 14.9299 21.97 14.9899 21.97 15.0599Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.5 9H8.97' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.5 13H8.97' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.4707 11.53V14.75C17.4707 15.99 16.4607 17 15.2207 17C13.9807 17 12.9707 15.99 12.9707 14.75V10.52L17.4707 11.53Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.9607 14.8703C21.9007 16.0503 20.9207 17.0003 19.7207 17.0003C18.4807 17.0003 17.4707 15.9903 17.4707 14.7503V11.5303L19.5007 11.9803C20.8207 12.2703 21.9007 12.9503 21.9607 14.8703Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

@media (max-width: 1024px) {
  .CityCard {
    width: 100%;
  }
}

.ReadyToGo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  // height: 100%;
  padding-bottom: 59px;
  padding-left: 46px;
  padding-right: 46px;

  h1 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 53px;
    text-align: center;
    color: var(--black-color);
  }

  .info {
    margin: 0;
    margin-bottom: 30px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: var(--black-color);

    .red {
      color: #cb2a33;
    }
  }

  .videoSectionsList {
    width: 312px;
    margin: 0;
    margin-bottom: 66px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: var(--black-color);

      .duration {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        color: var(--black-color);
      }
    }
  }

  .help {
    width: 235px;
    margin: 0;
    margin-bottom: 47px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--black-color);
  }

  .buttonContainer {
    width: 336px;
  }
}

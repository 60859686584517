.Invited {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 142px;
  padding-bottom: 78px;

  .info {
    width: 521px;
    margin: 0;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    color: var(--black-color);
  }

  .buttons {
    display: flex;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .Invited {
    padding-top: 174px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 61px;
    .info {
      width: unset;
    }

    .buttons {
      width: 100%;
      flex-direction: column;
    }
  }
}

.option {
  min-height: 64px;
  max-height: 442px;
  padding-top: 22px;
  padding-left: 23px;
  padding-right: 23px;
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.3789 11.5C22.3789 17.299 17.6779 22 11.8789 22C6.07992 22 1.37891 17.299 1.37891 11.5C1.37891 5.70101 6.07992 1 11.8789 1C17.6779 1 22.3789 5.70101 22.3789 11.5Z' fill='%23C1C5D6' stroke='%23F2F4F8'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 11px top 20px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  border-radius: 15px;
  border: 1px solid #f2f4f8;
  overflow-y: auto;

  &.active {
    border-radius: 15px;

    &.no {
      background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.8789' cy='11.5' r='10.5' fill='%23242833' stroke='%23F2F4F8'/%3E%3Cpath d='M19.3789 11.5C19.3789 15.6421 16.021 19 11.8789 19C7.73677 19 4.37891 15.6421 4.37891 11.5C4.37891 7.35786 7.73677 4 11.8789 4C16.021 4 19.3789 7.35786 19.3789 11.5Z' fill='%23D52D34' stroke='%23D52D34'/%3E%3C/svg%3E%0A");
      background-color: var(--black-color);
      color: var(--white-color);
    }

    &.yes {
      background-attachment: local;
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='10.5' stroke='%23D52D34'/%3E%3Ccircle cx='11' cy='11' r='8' fill='%23D52D34'/%3E%3C/svg%3E%0A");
    }
  }

  &.no {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    border: 1px solid #f2f4f8;
    color: var(--gray-color);
  }
}

.options {
  // position: absolute;
  position: relative;
  // top: 0;
  top: -165px;
  z-index: 1;
  width: 100%;
  margin-bottom: -165px;
  background-color: var(--white-color);
  border: 1px solid #f2f4f8;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .addExperience {
    width: 180px;
    padding-top: 24px;
    padding-bottom: 18px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--gray-color);
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 12H16' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V8' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left 155px top 24px;
  }

  .header {
    display: flex;
    justify-content: center;
    height: 64px;
    padding-top: 22px;
    background-image: url(../../../../assets/images/experience.svg),
      url(../../../../assets/images/arrow-up.svg);
    background-repeat: no-repeat;
    background-position: left 16px top 19px, right 16px top 28px;
    background-color: var(--white-color);
    border-radius: 15px;

    .title {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .question {
    margin-bottom: 16px;
    padding-left: 22px;
    padding-right: 22px;

    span {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: var(--black-color);
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 0;
    padding: 0;
    padding-bottom: 25px;
    padding-left: 22px;
    padding-right: 22px;
    list-style: none;
  }
}

@media (max-width: 1024px) {
  .option {
    min-height: 48px;
    padding-top: 14px;
    font-size: 18px !important;
    background-position: right 11px top 12px;
  }

  .options {
    .header {
      .title {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: var(--black-color);
      }
    }
  }
}

.RecordVideoProgressMobile {
  position: fixed;
  top: 73px;
  left: 51px;
  right: 51px;
  z-index: 2;
  display: flex;
  gap: 4px;
  justify-content: space-between;

  .button {
    position: absolute;
    top: -12px;
    padding: 0;
    background-color: transparent;
    border: none;

    &.backButton {
      left: -16px;
      transform: translateX(-100%);
    }

    &.closeButton {
      right: -16px;
      transform: translateX(100%);
    }
  }
  &.absolute {
    position: absolute;
  }

  .step {
    flex: 1;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    border-radius: 20px;

    &.active {
      background-color: var(--brand-color);
    }

    &.completed {
      background-color: #00e4ac;
    }
  }

  .title {
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding-right: 19px;
    padding-left: 59px;
    background-color: var(--white-color);
    border-radius: 24px;

    h1 {
      position: relative;
      left: -20px;
      margin: 0;
      padding-top: 4px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      white-space: nowrap;
    }
  }
}

@media (max-height: 786px) {
  .RecordVideoProgressMobile {
    &.viewSample {
      .title {
        display: none;
      }
    }
  }
}

.RecordVideoControls {
  display: flex;
  flex-direction: column;
  padding-top: 59px;

  h1 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    text-align: center;
    color: var(--black-color);
  }

  p {
    margin: 0;
  }

  .instructions {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--brand-color);
  }

  .text {
    margin-bottom: 40px;
    p {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .info {
    p {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: 26px;
    margin-top: auto;

    .whiteButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 358px;
      height: 54px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: var(--black-color);
      background-color: var(--white-color);
      box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      border: none;
    }
  }
}

.Job {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;

  .textInputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .jobTitleHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 20px;

      button {
        padding: 0;
        font-family: National 2 Condensed;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        color: #ff2d55;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    label {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: var(--black-color);
    }

    input {
      height: 64px;
      padding-left: 12px;
      padding-right: 12px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--black-color);
      background-color: #f2f4f8;
      border: 1px solid #f2f4f8;
      border-radius: 10px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--gray-color);
      }
    }
  }

  .dates {
    display: flex;
    gap: 19px;

    .dateContainer {
      position: relative;
      flex: 1;

      .calendarContainer {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;

        &.startDate {
          right: unset;
          left: 0;
        }
      }

      h2 {
        margin: 0;
        margin-bottom: 8px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--black-color);
      }

      .date {
        display: flex;
        align-items: center;
        height: 48px;
        padding-left: 12px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--black-color);
        background-color: #f2f4f8;
        border: 1px solid #f2f4f8;
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Job {
    gap: 20px;

    .dates {
      .dateContainer {
        .calendarContainer {
          position: fixed;
          z-index: 1000;
          transform: scale(0.9);
        }
      }
    }

    .textInputContainer {
      input {
        height: 48px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.dialog {
  max-width: 908px;
}

.modalContent {
  border-radius: 20px;
  overflow: hidden;
}

.DocModal {
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 170px);
    padding-top: 37px;
    padding-left: 51px;
    padding-right: 60px;
    padding-bottom: 49px;
    background-color: var(--secondary-color);

    .closeIcon {
      position: absolute;
      top: 47px;
      right: 22px;
      display: block;
      width: 32px;
      height: 32px;
      font-size: 0;
      background-image: url(../../assets/images/doc-modal-close-icon.svg);
      background-repeat: no-repeat;
      cursor: pointer;
    }

    h1 {
      margin-bottom: 16px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 46px;
      line-height: 52px;
      color: var(--black-color);
    }

    .doc {
      flex: 1;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
    }
  }
}

@media (max-width: 1024px) {
  .DocModal {
    .content {
      padding-top: 23px;
      padding-left: 19px;
      padding-right: 27px;
      padding-bottom: 29px;

      h1 {
        font-size: 28px;
        line-height: 35px;
      }

      .closeIcon {
        top: 25px;
        right: 10px;
      }
    }
  }
}

.WhiteButton {
  width: 100%;
  height: 58px;
  padding: 0;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  color: #d41e28;
  background-color: var(--white-color);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 16px;
  border: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .WhiteButton {
    height: 54px;
    font-size: 22px;
    line-height: 30px;
  }
}

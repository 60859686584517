.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 48px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: var(--secondary-color);

  &.iframe {
    padding-left: 39px;
    padding-right: 39px;
  }

  .logo {
    height: 25px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  .list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    &.userMenu {
      gap: 30px;

      .listItem {
        position: relative;
      }

      .search {
        position: absolute;
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_925_24407)'%3E%3Cpath d='M11 18C15.4183 18 19 14.4183 19 10C19 5.58172 15.4183 2 11 2C6.58172 2 3 5.58172 3 10C3 14.4183 6.58172 18 11 18Z' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.9999 20.0004L16.6499 15.6504' stroke='%23C1C5D6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_925_24407'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        background-color: var(--white-color);
        background-repeat: no-repeat;
        background-position: left 16px center;
        border-radius: 15px;

        .closeSearch {
          position: absolute;
          top: calc(50% - 10px);
          right: 20px;
          cursor: pointer;
        }

        input {
          width: 322px;
          height: 50px;
          padding-top: 5px;
          padding-left: 43px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          background-color: transparent;
          border-radius: 15px;
          border: none;

          &::placeholder {
            color: var(--gray-color);
          }

          &:focus {
            outline: none;
            border: 1px solid var(--white-color);
            box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
          }
        }
      }

      .listItem {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        a,
        button {
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 40px;
          display: flex;
          align-items: center;
          text-align: center;
          text-decoration-line: underline;
          color: var(--black-color);
        }

        button {
          padding: 0;
          background-color: transparent;
          border: none;
        }

        .signInLink {
          color: var(--brand-color);
        }

        .searchButton {
          padding: 0;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
      }
    }

    &.mainNav {
      .listItem {
        display: flex;
        justify-content: center;
        width: 212px;

        .navLink {
          span {
            color: var(--brand-color);
          }
        }

        a {
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 40px;
          text-align: center;
          text-decoration-line: underline;
          color: var(--black-color);

          &.active {
            padding-left: 20px;
            padding-right: 20px;
            background-color: var(--white-color);
            border-radius: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    align-items: baseline;
    padding-top: 21px;
    padding-left: 16px;
    padding-right: 16px;
    .logo {
      height: 12px;

      img {
        height: 12px;
        object-fit: contain;
      }
    }

    .list {
      &.mainNav {
        gap: 0;
        .listItem {
          width: unset;

          a {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 16px;
            line-height: 16px;
            white-space: nowrap;
            margin-bottom: -4px;

            span {
              line-height: 27px;
            }

            &.active {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
